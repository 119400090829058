import React from "react";
import styled from "styled-components";
import Clock from "../components/Clock";
import { Link } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;
  text-align: center;

  @media (max-width: 700px) {
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-4rem {
    margin-top: 4rem;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }


  a {
    color: white;
  }

  .subtext {
    color: #6d6d6d;
    font-size: 0.7rem;
  }
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 3px auto;
    line-height: 1.5em;
  }
`;

const HeaderImage = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4em;

  .logo-container {
    position: relative;
    width: 50%;
  }

  .logo-container .logo-backdrop {
    --inner-margin: 10px;
    position: absolute;
    top: var(--inner-margin);
    left: var(--inner-margin);
    width: calc(100% - var(--inner-margin) * 2);
    height: calc(100% - var(--inner-margin) * 2);
    border-radius: 50%;
    z-index: -1;
    animation: pulse 6s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      box-shadow: 1px 5px 350px rgba(4, 128, 130, 255),
        1px 5px 200px rgba(4, 128, 160, 0.75);
    }
    50% {
      box-shadow: 1px 5px 200px rgba(4, 128, 130, 255);
    }
    100% {
      box-shadow: 1px 5px 350px rgba(4, 128, 130, 255),
        1px 5px 200px rgba(4, 128, 160, 0.75);
    }
  }

  .logo-container img {
    width: 100%;
  }

  .logo-text {
    font-family: 'Noto Sans Runic', sans-serif;
    color: #d9d9d9;
    text-shadow: 1px 5px 12px #000A;
    font-size: 4em;
  }

  @media (max-width: 700px) {
    img {
      width: 80%;
    }

    .logo-text {
      font-size: 2.5em;
    }
  }
`;

const Sponsors = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4em;

  a > img {
    height: 70px;
    opacity: 0.75;
  }

  a:nth-child(2) {
    margin-top: 80px;
    padding: 0 90px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    a {
      margin: 10px auto;
    }

    a:nth-child(2) {
      margin-top: 10px;
      padding: 0;
    }
  }
`;

const StyledClock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Clock-title {
    margin-bottom: 1rem;
  }

  .Clock {
    width: 100%;
    max-width: 350px;
    background-color: #1c1f23;
    border: 2px solid #16181a;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  .Clock .Clock-unit {
    flex: 1;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: "Red Hat Mono", monospace;
    color: #999999;
  }

  .Clock .Clock-unit .Clock-value {
    font-size: 2em;
    font-weight: 500;
  }

  .Clock .Clock-unit .Clock-label {
    font-size: 0.8em;
  }
`;

const CalenderEvents = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div a {
    padding: 0 10px;
  }

  div a img {
    vertical-align: middle;
    filter: invert(0.7) sepia(0.4);
    transition: all .2s;
  }

  div:hover a:not(:hover) img {
    opacity: 0.25;
    filter: invert(0.7) sepia(0.4) blur(1px);
  }
`;

const NiceButton = styled.a`
  padding: 1rem 2rem;
  font-size: 1.25rem;
  position: relative;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  text-align: center;
  text-decoration: none;

  &.mt-50 {
    margin-top: 50px;
  }

  &.mb-50 {
    margin-bottom: 50px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 15px);
    height: 60px;
    transition: 0.5s ease;
  }

  &::after {
    top: -15px;
    left: -15px;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    bottom: -15px;
    right: -15px;
    border-left: none;
    border-top: none;
  }

  &:hover:after,
  &:hover:before {
    width: calc(100% - 30px);
    height: 80px;
  }
`;

export function Home() {
    const today = Date.parse(new Date());
    const ctfOpen = "Mar 23 2024 12:00:00 UTC+0100";
    const ctfDay = "Mar 23 2024 00:00:00 UTC+0100";
    const ctfEnd = "Mar 23 2024 20:00:00 UTC+0100"; // Duration: 8 hours
    const registration = "Mar 22 2024 12:00:00 UTC+0100";
    const ctfHasOpen = Date.parse(ctfOpen) - today < 0;
    const isCtfDay = Date.parse(ctfDay) - today < 0;
    const ctfHasEnded = Date.parse(ctfEnd) - today < 0;
    const registrationHasOpen = Date.parse(registration) - today < 0;
    let counterTitle = "Tävlingen börjar om";
    if (isCtfDay) {
        counterTitle = "Tävlingen börjar idag om";
        document.querySelectorAll(".Clock-value").forEach(el => {
            el.style.color = "var(--color-lime)";
        });
    }
    return (
        <PageContainer>
            <PageContent>
                <Header>
                    <p>
                        <date>23 mars 2024</date>
                    </p>
                    <h1 className="mono mono-500">HAR DU VAD SOM KRÄVS?</h1>
                    <p className="mono">
                        Prova på att lösa utmaningarna vi ställs inför varje dag. <br />
                        Tre myndigheter, åtta timmar, tjugofyra utmaningar inom:<br />
                        IT-forensik, kryptografi, programmering, med mera. <br />
                        Var med och tävla i Undutmaning CTF 2024.
                    </p>
                </Header>
                <HeaderImage>
                    <div className="logo-container">
                        <img alt="logo" src="/static/img/logo24.png" />
                        <div className="logo-backdrop" />
                    </div>
                    {/* UᚺDUTᛗAᚺᚾᚺG 24 */}
                    <h1 className="logo-text">UNDUTMANING 24</h1>
                </HeaderImage>
                {!ctfHasEnded ? (
                    <>
                        {registrationHasOpen && !ctfHasOpen && (
                            <>
                                <Header className="mt-4rem">
                                    <h2 className="mono mono-500">Registreringen har öppnat!</h2>
                                    <p className="mono">
                                        Registrera dig till CTF tävlingsplatformen. <br />
                                        Detta kan göras innan eller under tävlingen.
                                    </p>
                                </Header>
                                <NiceButton href="https://undutmaning.ctfd.io" target="_blank" className="mono mt-50">
                                    <span>Tävlingsplattformen</span>
                                </NiceButton>
                            </>
                        )}
                        <StyledClock>

                            {ctfHasOpen ? (
                                <>
                                    <h2 className="Clock-title mono mono-500" style={{
                                        color: "var(--color-lime)",
                                    }}>

                                        Tävlingen har börjat!
                                    </h2>
                                    <p className="mono">
                                        Spela nu och tävla om priser! <br />
                                        Tävlingen pågår till kl 20:00.
                                    </p>
                                    <NiceButton href="https://undutmaning.ctfd.io/" target="_blank" className="mono mt-50 mb-50">
                                        <span>Tävlingsplattformen</span>
                                    </NiceButton>
                                </>
                            ) : (
                                <h3 className="Clock-title mono">
                                    {counterTitle}
                                </h3>
                            )}
                            <Clock
                                deadline={ctfOpen}
                                labels={{
                                    days: "dagar",
                                    hours: "timmar",
                                    minutes: "minuter",
                                    seconds: "sekunder"
                                }}
                            />
                        </StyledClock>
                        {!registrationHasOpen && (<p className="mono subtext mt-5">
                            Registreringen till tävlingsplatformen <br />
                            öppnar den 22:a mars kl 12:00
                        </p>)}
                        <CalenderEvents>
                            <p className="mono">Lägg till CTF i kalendern</p>
                            <div>
                                <a
                                    href="https://www.google.com/calendar/render?action=TEMPLATE&text=Undutmaning%20CTF%202024&dates=20240323T110000Z/20240323T190000Z&details=Undutmaning%20CTF%202024%0D%0AInformation%20om%20eventet%20och%20t%C3%A4vlingen%20hittas%20p%C3%A5%20https%3A%2F%2Fundutmaning.se&location=&trp=true&sf=true&output=xml#f"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="/static/img/google.svg" alt="Google" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-ctf-2024.ics"
                                    download
                                >
                                    <img src="/static/img/outlook.svg" alt="Outlook" width="24" />
                                </a>
                                <a
                                    href="webcal://undutmaning.se/static/undutmaning-ctf-2024.ics"
                                    download
                                >
                                    <img src="/static/img/apple-173.svg" alt="Apple" width="24" />
                                </a>
                                <a href="/static/undutmaning-ctf-2024.ics" download>
                                    <img
                                        src="/static/img/download-backup-svgrepo-com.svg"
                                        alt="iCal"
                                        width="24"
                                    />
                                </a>
                            </div>
                        </CalenderEvents>
                    </>
                ) : (
                    <>
                        <Header className="mt-50">
                            <h2 className="mono mono-500 mt-50">TÄVLINGEN ÄR ÖVER</h2>
                            <p className="mono">
                                Tack till alla som deltog i årets CTF. <br />
                                Årets lösningar kommer att publiceras inom kort.<br />
                                <b>Vi ses nästa år!</b>

                                {/* <br />
                                <br />
                                <br />
                                <NiceButton href="/results" className="mono mt-50 mb-20">
                                    <span>Resultat</span>
                                </NiceButton> */}
                            </p>
                            <h2 className="mono mono-500 mt-50">VINNARE 2024</h2>
                            <ol className="mono" style={{ fontSize: "125%" }}>
                                <li>RoyalRoppers</li>
                                <li>ChaGU</li>
                                <li>MSAB</li>
                            </ol>
                        </Header>
                    </>
                )}

                <span class="mt-50" />
                <Header className="mt-50">
                    <p>
                        <date>25 april 2024</date>
                    </p>
                    <h1 className="mono mono-500">VÅRT FYSISKA EVENT</h1>
                    <p className="mono">
                        Glöm inte bort att anmäla dig för att tävla <br />
                        om en plats på det fysiska eventet! <br />
                        Läs mer om eventet <a href="/event">här</a>.
                    </p>
                </Header>
                {/* <NiceButton href="/register" className="mono mt-50 mb-20">
                    <span>Registrera dig</span>
                </NiceButton> */}
                <CalenderEvents>
                    <p className="mono">Lägg till event i kalendern</p>
                    <div>
                        <a
                            href="https://www.google.com/calendar/render?action=TEMPLATE&text=Undutmaning%20Event%202024&dates=20240425T140000Z/20240425T180000Z&details=Undutmaning%20Event%202024%0D%0AInformation%20om%20eventet%20hittas%20p%C3%A5%20https%3A%2F%2Fundutmaning.se%2Fevent&location=To%20be%20announced&trp=true&sf=true&output=xml#f"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/static/img/google.svg" alt="Google" width="24" />
                        </a>
                        <a
                            href="webcal://undutmaning.se/static/undutmaning-event-2024.ics"
                            download
                        >
                            <img src="/static/img/outlook.svg" alt="Outlook" width="24" />
                        </a>
                        <a
                            href="webcal://undutmaning.se/static/undutmaning-event-2024.ics"
                            download
                        >
                            <img src="/static/img/apple-173.svg" alt="Apple" width="24" />
                        </a>
                        <a href="/static/undutmaning-event-2024.ics" download>
                            <img
                                src="/static/img/download-backup-svgrepo-com.svg"
                                alt="iCal"
                                width="24"
                            />
                        </a>
                    </div>
                </CalenderEvents>
                <Sponsors>
                    <Link to="/agencies#fra">
                        <img
                            src="/static/img/crest_fra.svg"
                            alt="Försvarets Radioanstalt"
                        />
                    </Link>
                    <Link to="/agencies#must">
                        <img src="/static/img/crest_fm.svg" alt="Försvarsmakten" />
                    </Link>
                    <Link to="/agencies#sapo">
                        <img src="/static/img/crest_sapo.svg" alt="Säkerhetspolisen" />
                    </Link>
                </Sponsors>
            </PageContent>
        </PageContainer>
    );
}
