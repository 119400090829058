import React from "react";
import { Link, Route, Routes } from "react-router-dom"
import { Registrera } from "./routes/Registrera";
import { NotFound } from "./routes/NotFound";
import { Kontakt } from "./routes/Kontakt";
import { Event } from "./routes/Event";
import { Community } from "./routes/Community";
import { Agencies } from "./routes/Agencies";
import { Rules } from "./routes/Rules";
import { Home } from "./routes/Home";
import './App.css';
import { Info } from "./routes/Info";
import styled from "styled-components";

const NavLinkContainer = styled.div`
  display: flex;

  @media (max-width: 700px) {
    .nav-main {
      display: none;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .nav-main.open {
      display: flex;
      justify-content: flex-end;
      background-color: #0005;
    }

    .nav-background {
      background-color: #292929;
      padding: 2rem 4rem 0 1rem;
      
      ul {
        flex-direction: column;

        li {
          margin-bottom: 1rem;
        }

        li:not(:hover) {
          border-bottom: 4px solid #0000;
        }
      }
    }
  }
  `;

const NavSidebarMenu = styled.ul`
  display: none;

  @media (max-width: 700px) {
    display: flex;
  }
`;

function App() {
  let [open, setOpen] = React.useState(false);
  return (
    <>
      <nav className="navbar">
        <div className="logo nav-item"><Link to="/">UNDUTMANING</Link></div>
        <NavLinkContainer>
          <div className={"nav-main " + (open ? "open": "")} onClick={() => setOpen(!open)}>
            <div className="nav-background">
              <ul className="nav-links">
                <li className="nav-item"><Link to="/event">Event</Link></li>
                <li className="nav-item"><Link to="/agencies">Myndigheterna</Link></li>
                <li className="nav-item"><Link to="/info">Information</Link></li>
                <li className="nav-item"><Link to="/community">Gemenskap</Link></li>
                <li className="nav-item"><Link to="/rules">Regler</Link></li>
              </ul>
            </div>
          </div>
          <NavSidebarMenu className="nav-links" onClick={() => setOpen(!open)}>
            <li className="nav-item">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">Meny</a>
            </li>
          </NavSidebarMenu>
        </NavLinkContainer>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/registrera" element={<Registrera />} /> */}
        <Route path="/register" element={<Registrera />} />
        <Route path="/event" element={<Event />} />
        <Route path="/agencies" element={<Agencies />} />
        <Route path="/info" element={<Info />} />
        <Route path="/community" element={<Community />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
